import { getLocal, saveLocal} from '@/libs/util'

// 新增视频开关队列
export const addLocaVideoOffOn = ( devId,status ) => {
   
    let videoList = getQueueInfo()
    if(videoList){
        videoList[devId] = status
        saveLocal('videoList',videoList)
    }else{
        let data = new Object
        data[devId] = status
        saveLocal('videoList',data)
    }
}

// 是否需要更新视频加载状态，如果视频存在队列，并且状态没有更新，需要加载视频加载栏
export const isLoadingUpdate = (devId,status) => {

    let videoList = getQueueInfo()
    if(!videoList || !videoList.hasOwnProperty(devId)) return false
    //  如果状态相同，说明视频状态更新成功，将数据清除队列
    let result = videoList[devId] == status
 
    if(result) {
         // 删除设备队列 ，保存队列
         delete videoList[devId]
         saveLocal('videoList',videoList)
    }
    return !result
    
}
// 获取队列列表
export const getQueueInfo = devId => {
    let videoList = getLocal('videoList')
    videoList = videoList ? JSON.parse(videoList) : null
    if(!devId) return videoList
    if(videoList && videoList.hasOwnProperty(devId)) return videoList[devId]    
    
}
// 整合 解析 id
export const splitOrMergeId = (data,symbol = '&') => {
    let result = null
    if(typeof data == 'string' && data.includes(symbol)){
       result = data.split(symbol)
       return result
    } 
    if(Array.isArray(data) && data.length){
       result = data.join(symbol)
       return result
    } 
}
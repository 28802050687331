<template>
  <div class="video_main_box wh100 pr">
    <HnContent :hasData='hasData'>
      <template slot="left">
        <HnMenu :menuDevType='menuDevType' :defaultOpenMenu='defaultOpenMenu' :isLive='true' @change='devChange'></HnMenu>
      </template>
      <template slot="right">
        <div class="right_content_box wh100 video-box-bg-image">
          <div>
            <div v-loading="changLoading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(21, 35, 45, 0.8)" class="video_content_box">
              <div :class="['flipWarp', 'wh100',isflip? 'flip180':'']">
                <div>
                  <template v-if="playNub === 'one'">
                    <template v-if="videoStatus(videoPlayList[0]) == onlineData">
                      <div class="wh100 one_video_box" id="video-one-box-0"></div>
                    </template>
                    <template v-else-if="videoStatus(videoPlayList[0]) == 3">
                      <div class="wh100 boxBg acc statusNotOnlie">
                        <span :class="['iconfont',videoPlayList[0].videoInfo.devType == '球机'? 'icon-qiuji' : 'icon-qiangjifanzhuan', 'offNine']"></span>
                        <span>离线</span>
                      </div>
                    </template>
                    <template v-else-if="videoStatus(videoPlayList[0]) == 2">
                      <div class="wh100 boxBg acc statusNotOnlie">
                        <span :class="['iconfont',videoPlayList[0].videoInfo.devType == '球机'? 'icon-qiuji' : 'icon-qiangjifanzhuan', 'standby']"></span>
                        <span>待机</span>
                      </div>
                    </template>
                    <template v-if="offVideo">
                      <HnSyncLoading :loading="isLoading(videoPlayList[0].videoInfo,0)" @onEnd='syncOnend(videoPlayList[0],0)' width='70' :speedTime='4500' types="line">
                      </HnSyncLoading>
                    </template>
                    <template v-else>
                      <HnSyncLoading :loading="true" @onEnd='syncOffend(videoPlayList[0],0)' width='70' :speedTime='1000' types="line">
                      </HnSyncLoading>
                    </template>
                  </template>
                  <template v-else>
                    <ul :class="[playNub+'_video_box','wh100']">
                      <template v-for="(item,i) of videoPlayList">
                        <li :key="domeId(i)" class="pr">
                          <template v-if="videoStatus(item) == onlineData">
                            <div :class="['wh100',isFaceActice(item.videoInfo.devId,i)? 'videoActive':'bordert']" @click="checkVideo(item.videoInfo,i)" :id="domeId(i)"></div>
                          </template>
                          <template v-else-if="videoStatus(item) == 3">
                            <div :class="['wh100', 'boxBg', 'acc', 'statusNotOnlie',isFaceActice(item.videoInfo.devId,i)? 'videoActive':'bordert']" @click="checkVideo(item.videoInfo,i)">
                              <span :class="['iconfont',item.videoInfo.devType == '球机'? 'icon-qiuji' : 'icon-qiangjifanzhuan','offNine']"></span>
                              <span>离线</span>
                            </div>
                          </template>
                          <template v-else-if="videoStatus(item) == 2">
                            <div :class="['wh100', 'boxBg', 'acc', 'statusNotOnlie',isFaceActice(item.videoInfo.devId,i)? 'videoActive':'bordert']" @click="checkVideo(item.videoInfo,i)">
                              <span :class="['iconfont',item.videoInfo.devType == '球机'? 'icon-qiuji' : 'icon-qiangjifanzhuan', 'standby']"></span>
                              <span>待机</span>
                            </div>
                          </template>
                          <template v-else>
                            <addVideo :menus='menus' :i='i' class="bordert" @change="addVideoChange"></addVideo>
                          </template>
                          <template v-if="videoStatus(item)">
                            <span @click="delVideo(item,i)" class="iconfont icon-shanchu2 delVideoIcon baseColor cp"></span>
                            <!-- 4500 -->
                            <template v-if="offVideo">
                              <HnSyncLoading :loading="isLoading(item.videoInfo,i)" @onEnd='syncOnend(item,i)' width='70' :speedTime='4500' types="line">
                              </HnSyncLoading>
                            </template>
                            <template v-else>
                              <HnSyncLoading :loading="true" @onEnd='syncOffend(item,i)' width='70' :speedTime='1000' types="line">
                              </HnSyncLoading>
                            </template>
                          </template>
                        </li>
                      </template>
                    </ul>
                  </template>
                </div>
                <div class="pageBg">
                  <div class="nowPicTitle">
                    <p>
                      <span class="baseColor iconfont el-icon-picture"></span>
                      <span>实时图片</span>
                    </p>
                    <span class="el-icon-close cp" @click="imageFlip"></span>
                  </div>
                  <el-image style="width: 100%; height: 100%" :src="nowImgUrl" fit="contain"></el-image>
                </div>
              </div>
            </div>
            <div class="video_info_box pr" v-if="videoDevInfo">
              <span class="iconfont icon-xiangce xiangce_box cp" @click="albumShow"></span>
              <!-- 方向控制 -->
              <div class="video_ctl_main_box">
                <template v-if="isCtlRole">
                  <div v-for="item of videoCtl" :key="item" @click="videoCtlChange(item)" :class="['leftTriangle',isCtl? 'activeTriangle':'cp']">
                    <el-tooltip class="item" effect="dark" :disabled='isCtl' content="设备不支持" placement="top">
                      <span v-loading="ctlLoad[item]" :class="[
                                                        'iconfont', 
                                                        'icon-shixinjiantou-xiangshang-copy',
                                                        !isCtl?'notClick':'',
                                                        ]">
                      </span>
                    </el-tooltip>
                  </div>
                  <div class="picture_box">
                    <div>
                      <template v-if="!cameraLoading && isPhonePic">
                        <span class="el-icon-camera-solid cp" @click="cameraClick"></span>
                      </template>
                      <template v-else-if="!isPhonePic">
                        <el-tooltip class="item" effect="dark" content="设备不支持" placement="top">
                          <span class="el-icon-lock"></span>
                        </el-tooltip>
                      </template>
                      <template v-else>
                        <span element-loading-background="rgba(0, 0, 0, 0)" v-loading="cameraLoading"></span>
                      </template>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <el-tooltip class="item" effect="dark" content="用户无权限" placement="top">
                    <p class="notCtl_box disabled">
                      <span class="el-icon-lock standbyColor"></span>
                    </p>
                  </el-tooltip>
                </template>
              </div>
              <div class="tools_but_box">
                <!-- 缩小 isCtlRole -->
                <el-tooltip class="item" effect="dark" :disabled='isZoom && isCtlRole' :content="isCtlRole? '设备不支持':'用户无权限'" placement="top">
                  <el-button :type="isZoom && isCtlRole? 'success':'info'" :class="['but_box', isZoom && isCtlRole ? 'but_base':'disabled']" icon="iconfont icon-suoxiao1" @click="videoCtlChange('small',true)" :loading="ctlLoad['small']" size="medium" plain></el-button>
                </el-tooltip>
                <!-- 放大 -->
                <el-tooltip class="item" effect="dark" :disabled='isZoom && isCtlRole' :content="isCtlRole? '设备不支持':'用户无权限'" placement="top">
                  <el-button :type="isZoom && isCtlRole? 'success':'info'" :class="['but_box', isZoom && isCtlRole ? 'but_base':'disabled']" icon="iconfont icon-sousuofangda" @click="videoCtlChange('big',true)" :loading="ctlLoad['big']" size="medium" plain></el-button>
                </el-tooltip>
                <!-- 翻转 请求接口测试 -->
                <template v-if="!devIsdh">
                  <el-tooltip class="item" effect="dark" :disabled='isFlip && isCtlRole' :content="isCtlRole? '设备不支持':'用户无权限'" placement="top">
                    <el-button :type="isFlip && isCtlRole? 'success':'info'" :class="['but_box', isFlip && isCtlRole ? 'but_base':'disabled']" icon="iconfont icon-shangxia" @click="mirrorCtrl" size="medium" plain></el-button>
                  </el-tooltip>
                </template>
              </div>
              <div class="dev_info_box video-infoList-bg-image" v-if="videoDevInfo">
                <div class="video-infoList-subBg-image">
                  <span>名称</span>
                  <el-tooltip class="item" effect="dark" :disabled='devNameTipsShow' :content="devName" placement="top">
                    <span class="sinleline" ref="devNameDome">{{ devName }}</span>
                  </el-tooltip>
                </div>
                <div class="video-infoList-subBg-image">
                  <span>序列码</span>
                  <el-tooltip class="item" effect="dark" :disabled='devCodeTipsShow' :content="videoDevInfo.devCode" placement="top">
                    <span class="sinleline" ref="devCodeDome">{{ videoDevInfo.devCode }} </span>
                  </el-tooltip>
                </div>
                <div class="video-infoList-subBg-image">
                  <span>在线状态</span>
                  <!-- <span class='sinleline'>{{ isOnlineStr }}</span> -->
                  <template v-if="!isOnlineText">
                    <template v-if="isOnlineStr == '离线' || !videoDevInfo.actuator">
                      <span class='sinleline'>{{ isOnlineStr }}</span>
                    </template>
                    <template v-else>
                      <el-tooltip class="item" effect="dark" :content="toolTipContent" placement="top">
                        <span :class="[ 'sinleline',
                                                    isOnlineStr == '在线'? 
                                                    'baseColor el-icon-switch-button cp':
                                                    isOnlineStr == '待机'?'standbyColor el-icon-switch-button cp':'']" @click="standbyClick">
                          {{ isOnlineStr }}
                        </span>
                      </el-tooltip>
                    </template>
                  </template>
                  <template v-else>
                    <span>{{ isOnlineText }}...</span>
                  </template>
                </div>
                <div class="video-infoList-subBg-image">
                  <span>所在地</span>
                  <span class="sinleline">
                    {{ videoDevInfo.devAddress || '无数据'}}
                  </span>
                </div>
                <div class="video-infoList-subBg-image">
                  <span>品牌</span>
                  <span class="sinleline">
                    {{ videoDevInfo.vendorName }}
                  </span>
                </div>
                <div class="video-infoList-subBg-image">
                  <span>类型</span>
                  <span class="sinleline">
                    {{ videoDevInfo.devType }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="bottom_content_box">
              <ul>
                <li v-for="item of playModelIcon" :key="item.code" @click="playModelChange(item.code)" :class="['iconfont', 
                                            'cp', 
                                            item.icon,
                                            playNub === item.code? 'modelActive':''
                                            ]"></li>
              </ul>
              <div :class="['video_tools_box',videoDevInfo?'marginRight':'']">
                <!-- <p class="hd_box active-cp" @click="hdChange">{{ hdText }}</p> -->
                <el-dropdown @command='hdChange' :tabindex='1' v-if="videoQuality && videoQuality.length && hdText">
                  <span class="el-dropdown-link">
                    {{hdText}}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                   
                    <el-dropdown-item v-for="item of videoQuality" :key="item.value" :class="[{'baseColor': item.value == isHd}]" :command='item.value'>{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <template v-if="!videoDevInfo">
                  <el-alert :title="notActiveText" type="warning" class="alert_box" :closable='false' show-icon>
                  </el-alert>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </HnContent>
    <HnSplitPane direction="row" v-model='isSplit' :min="15" :max="80" :triggerLength="20" :paneLengthPercent.sync="paneLengthPercent">
      <div class="album_mian_box wh100">
        <div class="top_box">
          <p>
            <span class="baseColor iconfont icon-xiangce"></span>
            <span>相册</span>
          </p>
          <span class="el-icon-close cp" @click="splitColse"></span>
        </div>
        <div class="tools_box">
          <el-date-picker v-model="searchParamDateVal" @change="searchParamDateChange" class="date_picker_box" type="daterange" align="right" size="small" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
          <ul class="boxsubBg dateType_box">
            <li :class="[picFromInfo.timeType == item.code? 'active':'', 'weather-cp']" v-for="item of picTimeList" :key="item.code" @click="paramTimeChange(item.code)">{{ item.name }}</li>
          </ul>
        </div>
        <div class="content_box" element-loading-background="rgba(0, 0, 0, 0.2)" element-loading-spinner="el-icon-loading" v-loading="picListLoading">
          <div class="wh100" style="overflow:auto">
            <ul class="infinite-list cc" :infinite-scroll-disabled="picDisabled" v-infinite-scroll="picLoad">
              <li v-for="(item,i) in picList" :key="i" class="infinite-list-item pr cp">
                <el-image style="width: 100%;height: 100%" :src="item.filePath" :preview-src-list="[item.filePath]" fit="cover">
                  <div slot="error" class="image-slot boxBg">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <p>{{ item.photoTime || '无日期' }}</p>
              </li>
            </ul>
            <p v-if="picLoading">加载中...</p>
            <p v-if="picNoMore">没有更多了</p>
          </div>
        </div>
      </div>
    </HnSplitPane>
    <getPwd :isShow.sync='isPwdShow' :ctlData='ctlData' @onOk='getPwd'></getPwd>
  </div>
</template>

<script>

import addVideo from './components/addVideo.vue'
import config from '@/config'
import { deepClone } from '@/libs/util'
import getPwd from '@/components/localComponent/getPwd.vue'
import ctrPwdMixin from '@/mixins/ctrPwd'
import menuFun from '@/mixins/menuFun'
import { debounce } from "@/libs/util";
import {
  addLocaVideoOffOn,
  isLoadingUpdate,
  splitOrMergeId,
  getQueueInfo
} from './uitl'
let Hls = require('hls.js')
let _query = debounce((code, type, callBack) => {
  callBack(code, type);
}, 500);
export default {
  components: {
    addVideo,
    getPwd,
  },
  mixins: [ctrPwdMixin, menuFun],
  data () {
    return {
      devCodeTipsShow: true,
      devNameTipsShow: true,
      notActive: true,
      notActiveText: '请选择视频，获取视频操作信息',
      hls: '',
      changLoading: false,
      menus: [],
      devActiveId: '',
      playVideoActiveId: '',
      defaultOpenMenu: [0, 0], // 默认设备菜单，[0] 为展开索引 [1] 为激活菜单索引
      videoCtl: [
        'right',
        'bottom',
        'top',
        'left',
      ],
      ctlLoad: {
        'right': false,
        'bottom': false,
        'top': false,
        'left': false,
        'small': false,
        'big': false
      },
      isflip: false,
      paneLengthPercent: 80,
      activeCtl: '',
      playNub: 'one',
      playModelIcon: [
        {
          icon: 'icon-yigongge',
          code: 'one'
        },
        {
          icon: 'icon-sigongge',
          code: 'four'
        },
        {
          icon: 'icon-liugongge',
          code: 'six'
        },
        {
          icon: 'icon-jiugongge',
          code: 'nice'
        }
      ],
      videoPlayList: [
        {
          flvPlayer: null,
          videoInfo: null
        }
      ],
      videoDevInfo: null,
      cameraLoading: false,
      isSplit: false,
      nowImgUrl: '',
      picLoading: false,
      searchParamDateVal: '',
      picTotal: 0,
      picList: [],
      picTimeList: [
        {
          name: '今天',
          code: 1
        },
        {
          name: '三天',
          code: 2
        },
        {
          name: '七天',
          code: 3
        }

      ],
      picFromInfo: {
        pageSize: 14,
        pageIndex: 1,
        timeType: 1,
        sTime: '',
        eTime: ''
      },
      picListLoading: false,
      queueList: getQueueInfo(),
      onlineData: config.devStatus.online,
      ctlData: null,
      isPwdShow: false,
      ctlReqKey: '',
      speedTime: 200,
      offVideo: true,
      isHd: '',
      menuDevType: ['视频设备'],
      devName: ''
    }
  },
  watch: {
    videoDevInfo: {
      handler (val) {
        if (val) {
      
          let devName = val?.devName
          let devCode = val?.devCode
          let { vendorName, videoDeviceAddrs } = val
          if(!this.isHd){
            if (vendorName == '大华' ) {
              this.isHd = videoDeviceAddrs?.hls?.streamAddr ? 'bd'
                : videoDeviceAddrs?.hlshd?.streamAddr ? 'hd' : ''
            } else {
              this.isHd = videoDeviceAddrs?.flv?.streamAddr ? 'bd'
                : videoDeviceAddrs?.flvhd?.streamAddr ? 'hd' : ''
            }
          }
          this.$nextTick(() => {
            let nameDome = this.$refs.devNameDome
            let codeDome = this.$refs.devCodeDome
            if (devName && nameDome)
              this.devNameTipsShow = nameDome.scrollWidth > nameDome.offsetWidth ? false : true
            if (devCode && codeDome)
              this.devCodeTipsShow = codeDome.scrollWidth > codeDome.offsetWidth ? false : true
          })
        }
      },
      immediate: true
    }
  },
  beforeDestroy () {
    this.destroyPlay()
  },
  computed: {
    hdText () {
      return this.isHd == 'hd' ? '高清' : this.isHd == 'bd' ? '标清' : ''
    },
    isLoading () {
      return (devInfo, i) => {
        if (!this.queueList) return false
        let online = devInfo.onlineStatus
        //当设备状态为离线时，关闭加载组件 
        if (online == 0) return false
        // 拼接激活id字段    
        let data = [devInfo.devId, i, this.playNub]
        let splitId = splitOrMergeId(data)
        let reulstData = isLoadingUpdate(splitId, online)
        return reulstData
      }
    },
    isFaceActice () {
      return (devId, i) => {
        let data = [devId, i, this.playNub]
        let splitId = splitOrMergeId(data)
        let reulst = this.playVideoActiveId === splitId ? true : false
        return reulst
      }
    },
    domeId () {
      return i => {
        return `video-${this.playNub}-box-${i}`
      }
    },
    isOnlineStr () {
      let data = this.videoDevInfo.onlineStatus
      return !data || data == 0 ? '离线' : data == this.onlineData ? '在线' : '待机'
    },
    toolTipContent () {
      return this.isOnlineStr === '在线' ? '点击关闭视频' : '点击开启视频'
    },
    isOnlineText () {
      if (this.playVideoActiveId) {
        let saveStatus = getQueueInfo(this.playVideoActiveId)
        return saveStatus == this.onlineData ? '开机中' :
          saveStatus == 0 ? '关机中' : false
      }
      return false
    },
    isOnline () {
      let data = this.videoDevInfo.onlineStatus
      return data == this.onlineData ? true : false
    },
    devIsdh () {
      if (this.videoDevInfo &&
        this.videoDevInfo.vendorName == '大华'
      ) return true
      return false
    },
    videoQuality () {
      const info = this.videoDevInfo
      let arr = []
      if (info && info?.videoDeviceAddrs) {
        let { flv, flvhd, hls, hlshd } = info.videoDeviceAddrs
        if (info.vendorName == '大华') {
          // hls
          if (hls) arr.push({
            value: 'bd',
            label: '标清'
          })
          if (hlshd) arr.push({
            value: 'hd',
            label: '高清'
          })
        } else {
          //flv
          if (flv) arr.push({
            value: 'bd',
            label: '标清'
          })
          if (flvhd) arr.push({
            value: 'hd',
            label: '高清'
          })
        }
      }
      return arr
    },
    // 是否可控制    
    isCtl () {
      // 通过 是否包含设备信息 是否为球机 是否在线 为判断依据， 确定球机设备可以控制方向键   
      const info = this.videoDevInfo
      if (info &&
        info.isControl == 1 &&
        info.onlineStatus == this.onlineData
      ) {
        return true
      }
      return false
    },
    // 是否可翻转    
    isFlip () {
      const info = this.videoDevInfo
      if (info &&
        info.vendorName != '大华' &&
        info.isControl == 1 &&
        info.onlineStatus == this.onlineData
      ) {
        return true
      }
      return false
    },
    // 是否可缩放
    isZoom () {
      const info = this.videoDevInfo
      if (info &&
        info.isZoom == 1 &&
        info.onlineStatus == this.onlineData
      ) {
        return true
      }
      return false
    },
    // 是否可拍照
    isPhonePic () {
      const info = this.videoDevInfo
      if (info &&
        info.useType != 1 &&
        info.onlineStatus == this.onlineData
      ) {
        return true
      }
      return false
    },
    videoStatus () {
      return item => {
        if (item && item.videoInfo) {
          let data = item.videoInfo.onlineStatus
          return !data || data == 0 ? 3 : data
        } else {
          return false
        }
      }
    },
    picCount () {
      return this.picList.length
    },
    picNoMore () {
      return this.picCount >= this.picTotal
    },
    picDisabled () {
      return this.picLoading || this.picNoMore
    },
    isCtlRole () {
      let menu = this.$store.state.user.menuList
      let routePath = this.$route.path
      // 当路由相等 并且权限通过时 设置为视频可控
      const result = menu.some(item => item.path == routePath && item.funcs.includes('320402'))
      return result
    },
  },
  methods: {
    // 设备视频数据
    async reqIotVideoDeviceDataList (id) {
      try {
        this.changLoading = true
        const res = await this.$api.IotVideoDeviceDataList({ id })
        setTimeout(() => {
          this.changLoading = false
        }, 300);
        return res
      } catch {
        return false
      }
    },
    // 视频看板列表
    async reqIotVideoBoardList (code = this.playNub) {
      const boardList = {
        'four': 1,
        'six': 2,
        'nice': 3
      }
      this.changLoading = true
      const res = await this.$api.IotVideoBoardList({
        type: boardList[code]
      })
      setTimeout(() => {
        this.changLoading = false
      }, 300);
      this.playNub = code
      if (res && res.length) {
        let firstResData = res[0]
        let data = [firstResData.devId, firstResData.order, this.playNub]
        this.playVideoActiveId = splitOrMergeId(data)
        res.forEach(item => {
          // 根据设备id 请求视频详情
          this.reqIotVideoDeviceDataList(item.devId).then(
            val => {
              if (firstResData.devId === val.devId) this.videoDevInfo = val
              // 将看板id绑定到视频数据中，方便后台删除该数据
              if (val) this.setIndexVideo(Object.assign({}, val, { id: item.id }), item.order)
            }
          )
        })
      } else {
        this.playVideoActiveId = ''
        this.videoDevInfo = null
      }
    },
    // 添加视频看板
    async reqAddIotVideoBoard (data) {
      const res = await this.$api.AddIotVideoBoard(data)
      if (res) {
        const videoInfo = await this.reqIotVideoDeviceDataList(res.devId)
        if (videoInfo) {
          let data = [videoInfo.devId, res.order, this.playNub]
          this.playVideoActiveId = splitOrMergeId(data)
          this.videoDevInfo = videoInfo
          this.$msg.success('看板视频设置成功');
          this.setIndexVideo(Object.assign({}, videoInfo, { id: res.id }), res.order)
        }
      }
    },
    // 视频控制 开始
    async reqIotDeviceVideoCtrl (data) {
      this.ctlLoad[this.activeCtl] = true
      const res = await this.$api.IotDeviceVideoCtrl(data)
      setTimeout(() => {
        this.ctlLoad[this.activeCtl] = false
      }, 1500);
      if (res) this.addLocaCtrPwd(this.videoDevInfo.devId, data.ctrlPwd)
    },
    // 视频抓拍
    async reqIotDeviceVideoPhotoCapture (data) {
      this.cameraLoading = true
      const res = await this.$api.IotDeviceVideoPhotoCapture(data)
      if (res && res.includes('http')) {
        this.nowImgUrl = res
        this.isflip = true
      }
      this.cameraLoading = false
    },
    // 查看视频设备图片
    async reqIotDevicePhotoPageList () {
      const data = Object.assign({}, this.picFromInfo, { devId: this.videoDevInfo.devId })
      try {
        const res = await this.$api.IotDevicePhotoPageList(data)
        return res
      } catch {
        return false
      }
    },
    imageFlip () {
      this.isflip = false
      setTimeout(() => {
        this.nowImgUrl = ''
      }, 1000)
    },
    //待机开关
    standbyClick () {
      // 当在线时，执行关闭动作，其他执行开机动作   
      let resultAction = this.videoDevInfo.onlineStatus == this.onlineData ? 0 : this.onlineData
      let { actuator } = this.videoDevInfo
      let data = {
        id: actuator.devId,
        devCode: actuator.devCode,
        actuatorId: actuator.id,
        no: actuator.channelNo,
        reversalNo: actuator.reverseChannelNo,
        stopNo: actuator.closeChannelNo,
        openType: resultAction,
      }
      if (this.isNeedPwd()) {
        this.ctlData = data
        this.isPwdShow = true
        this.ctlReqKey = 'IotDeviceVideoOnOffCtrl'
      } else {
        let pwd = this.getPwdVal(this.videoDevInfo.devId)
        if (pwd) data.ctrlPwd = pwd
        // 请求设备控制接口  
        this.IotDeviceVideoOnOffCtrl(data)
      }
    },
    // 视频设备开关请求接口
    async IotDeviceVideoOnOffCtrl (data) {
      const res = await this.$api.IotDeviceVideoOnOffCtrl(data)
      if (res) {
        if (data.openType == 0) {
          //  当时关闭动作时，短加载
          this.offVideo = false
        } else {
          this.offVideo = true
          // 当设备成功后，添加队列，显示进度条  
          let activeId = this.playVideoActiveId
          addLocaVideoOffOn(activeId, data.openType)
          this.queueList = getQueueInfo()
        }
      }
    },
    //开关机加载动画 完成回调    
    syncOnend (data, i) {
      this.reqIotDeviceOnlineStatus(data, i)
      //   动画加载完成，请求是否成功回调
    },
    syncOffend (data, i) {
      this.reqIotDeviceOnlineStatus(data, i)
      this.offVideo = true
    },
    // 查询视频在线状态
    async reqIotDeviceOnlineStatus (data, i) {
      let { videoInfo } = data
      const res = await this.$api.IotDeviceOnlineStatus({ id: videoInfo.devId })
      if (res) {
        let codeData = [res.devId, i, this.playNub]
        let devIds = splitOrMergeId(codeData)
        // 执行关闭动作   
        if (videoInfo.onlineStatus == this.onlineData) {
          if (res.onlineStatus !== videoInfo.onlineStatus) {
            let cloneData = deepClone(videoInfo)
            cloneData.onlineStatus = 0
            this.setIndexVideo(cloneData, i)
            // 当数据id和默认激活id匹配时，更新默认激活id数据  
          }
          if (this.playVideoActiveId == devIds) this.videoDevInfo = cloneData
        } else {
          // 执行开机动作，
          const isNeedUpdate = isLoadingUpdate(devIds, videoInfo.onlineStatus)
          this.queueList = getQueueInfo() // 目的是更新在线状态字段
          // 当开关机成功时自动清除队列信息，动态改变视频值
          // 当开关机失败时，继续定时请求该接口
          if (isNeedUpdate) {
            let cloneData = deepClone(videoInfo)
            cloneData.onlineStatus = this.onlineData
            this.setIndexVideo(cloneData, i)
            // 当数据id和默认激活id匹配时，更新默认激活id数据  
            if (this.playVideoActiveId == devIds) this.videoDevInfo = cloneData
          } else {
            //   debugger
            setTimeout(() => {
              this.reqIotDeviceOnlineStatus(data, i)
            }, 30000)
          }
        }
      }
    },
    // 参数历史数据 固定日期选择
    async paramTimeChange (code) {
      this.$set(this.picFromInfo, 'sTime', '')
      this.$set(this.picFromInfo, 'eTime', '')
      this.$set(this.picFromInfo, 'timeType', code)
      this.$set(this.picFromInfo, 'pageIndex', 1)
      this.picListLoading = true
      const res = await this.reqIotDevicePhotoPageList()
      setTimeout(() => {
        this.picListLoading = false
      }, 300)
      if (res) {
        this.picTotal = res.totalCount
        this.picList = res.list
      }
    },
    // 参数历史数据 自定义日期选择
    async searchParamDateChange (data) {
      if (data && data.length == 2) {
        this.$set(this.picFromInfo, 'sTime', data[0])
        this.$set(this.picFromInfo, 'eTime', data[1])
        this.$set(this.picFromInfo, 'pageIndex', 1)
        this.$set(this.picFromInfo, 'timeType', 0)
        this.picListLoading = true
        const res = await this.reqIotDevicePhotoPageList()
        setTimeout(() => {
          this.picListLoading = false
        }, 300)
        if (res) {
          this.picTotal = res.totalCount
          this.picList = res.list
        }
      }
    },
    //查看相册
    async albumShow () {
      const res = await this.reqIotDevicePhotoPageList()
      if (res) {
        this.picTotal = res.totalCount
        this.picList = res.list
        this.isSplit = true
      }
    },
    // 设备切换
    async devChange (infos) {
  
      const devInfos = this.getDevInfo(infos)
      this.devName = devInfos ?.devName
      let data = infos[0]
      if (data) {
        //  let videoInfo = this.handleActiveDev(data.devId)
        let { devId } = data
        this.devActiveId = devId
        if (this.playNub !== 'one') {
          this.destroyPlay('one')
          this.playNub = 'one'
        } else {
          this.$nextTick(() => {
            let $dome = document.getElementById('video-one-box-0')
            if ($dome) $dome.innerHTML = ''
          })
        }
        const res = await this.reqIotVideoDeviceDataList(devId)
        if (res) {
          this.videoDevInfo = res
          this.setIndexVideo(res)
        }
      }
    },
    // 视频翻转配置
    async reqIotDeviceVideoMirrorCtrl (data) {
      const res = await this.$api.IotDeviceVideoMirrorCtrl(data)
      if (res) this.addLocaCtrPwd(this.videoDevInfo.devId, data.ctrlPwd)
    },
    mirrorCtrl () {
      let devInfo = this.videoDevInfo
      let playType = (devInfo.vendorName == '大华') ? 'hlshd' : 'flv'
      let data = {
        devId: devInfo.devId,
        devCode: devInfo.devCode,
        channelNo: devInfo.videoDeviceAddrs[playType].channelNo,
        ctrlType: 0
      }
      if (this.isNeedPwd()) {
        this.ctlData = data
        this.isPwdShow = true
        this.ctlReqKey = 'reqIotDeviceVideoMirrorCtrl'
      } else {
        let pwd = this.getPwdVal(devInfo.devId)
        if (pwd) data.ctrlPwd = pwd
        this.reqIotDeviceVideoMirrorCtrl(data)
      }
    },
    isNeedPwd () {
      let { isSetPwd, devId } = this.videoDevInfo
      let result = false
      if (isSetPwd) {
        result = this.isPwdPass(devId)  // 验证设备密码是否存在或过期   
        result = !result
      }
      return result
    },
    // 绑定视频数据   
    setIndexVideo (videoInfo, i = 0) {
      if (this.videoPlayList.length) {
        let { flvPlayer } = this.videoPlayList[i]
        if (flvPlayer) {
          flvPlayer.destroy()
          let videoBoxIdName = `video-${this.playNub}-box-${i}`
          let $dome = document.getElementById(videoBoxIdName)
          if ($dome) $dome.innerHTML = ''
        }
        this.$set(this.videoPlayList, i, { flvPlayer: null, videoInfo })
      } else {
        this.videoPlayList.push({
          flvPlayer: null,
          videoInfo
        })
      }
      this.$nextTick(this.createVideo(i))
    },
    //选中面板video   
    checkVideo (item, i) {
      let data = [item.devId, i, this.playNub]
      this.playVideoActiveId = splitOrMergeId(data)
      this.videoDevInfo = item
    },
    cameraClick () {
      if (this.isOnline) {
        let devInfo = this.videoDevInfo
        let playType = devInfo.vendorName == '大华' ? 'hlshd' : 'flv'
        let data = {
          devId: devInfo.devId,
          devCode: devInfo.devCode,
          channelNo: devInfo.videoDeviceAddrs[playType].channelNo,
        }
        this.reqIotDeviceVideoPhotoCapture(data)
      }
    },
    //  创建视频播放 当传入索引时，根据索引播放对应视频，或者遍历全部视频列表
    createVideo (i) {
      if (i || typeof i === 'number' && i < this.videoPlayList.length) {
        let { flvPlayer, videoInfo } = this.videoPlayList[i]
        // 根据设备是否在线，来确定是否加载视频 
        if (flvPlayer) flvPlayer.destroy()
        if (this.$flv.isSupported() && videoInfo.onlineStatus == this.onlineData) {
          this.$nextTick(() => {
            let { vendorName, videoDeviceAddrs } = videoInfo
            if (vendorName == '大华') {
              this.createHls(videoDeviceAddrs, i)
            } else {
              this.createFlv(videoDeviceAddrs, i)
            }
          })
        }
      } else {
        throw '索引大于视频列表长度！'
      }
    },
    // 加载hls视频流
    createHls (videoDeviceAddrs, i) {
      let hdKey = this.isHd == 'hd' ? 'hlshd' : 'hls'
      let url = videoDeviceAddrs[hdKey]?.streamAddr
      if (!url) return
      let videoIdName = `video-${this.playNub}-${i}`
      let videoBoxIdName = `video-${this.playNub}-box-${i}`
      let videoDome = `<video autoplay muted width= '100%' style= "object-fit: fill" height="100%" id="${videoIdName}" ></video>`
      document.getElementById(videoBoxIdName).innerHTML = videoDome;
      let videoElement = document.getElementById(videoIdName);
      let hls = new Hls();
      hls.loadSource(url); //
      hls.attachMedia(videoElement);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoElement.play();
      });
      this.$set(this.videoPlayList[i], 'flvPlayer', hls)
    },
    createFlv (videoDeviceAddrs, i) {
     
      let hdKey = this.isHd == 'hd' ? 'flvhd' : 'flv'
      let url = videoDeviceAddrs[hdKey]?.streamAddr
      if (!url) return
      let videoIdName = `video-${this.playNub}-${i}`
      let videoBoxIdName = `video-${this.playNub}-box-${i}`
      let videoDome = `<video autoplay controls width= '100%'  height="100%" style= "object-fit: fill" id="${videoIdName}"></video>`
      document.getElementById(videoBoxIdName).innerHTML = videoDome;
      let videoElement = document.getElementById(videoIdName);
      let player = this.$flv.createPlayer({
        type: 'flv',
        url: url,
        isLive: true, // 实时流
        hasAudio: false,
        hasVideo: true
        // hasAudio: true
      });
      player.attachMediaElement(videoElement);
      player.load();
      player.play();
      this.$set(this.videoPlayList[i], 'flvPlayer', player)
    },
    videoCtlChange (code, devType = false) {
      const data = Object.values(this.ctlLoad).some(val => val)
      if (data) {
        return
      }
      _query(code, devType, this.query)
    },
    query (code, devType) {
      // code ：控制方向 ；devType：是否为缩放动作
      if (!this.isCtlRole) return
      const isCtl = devType ? code && this.isZoom : code && this.isCtl
      if (isCtl) {
        this.activeCtl = code
        let devInfo = this.videoDevInfo
        let playType = devInfo.vendorName == '大华' ? 'hlshd' : 'flv'
        let data = {
          devId: devInfo.devId,
          devCode: devInfo.devCode,
          channelNo: devInfo.videoDeviceAddrs[playType].channelNo,
          ctrlType: config.videoCtlType[code],
          speed: 1
        }
        if (this.isNeedPwd()) {
          this.ctlData = data
          this.isPwdShow = true
          this.ctlReqKey = 'reqIotDeviceVideoCtrl'
        } else {
          let pwd = this.getPwdVal(devInfo.devId)
          if (pwd) data.ctrlPwd = pwd
          this.reqIotDeviceVideoCtrl(data)
        }
      } else {
        this.activeCtl = ''
      }
    },
    async hdChange (code) {
  
      if (code == this.isHd) return
      this.isHd = code
      this.destroyPlay()
      // 多宫格时，请求看板列表数据
      if (this.playNub != 'one') {
        this.reqIotVideoBoardList()
      } else {
        const res = await this.reqIotVideoDeviceDataList(this.devActiveId)
        if (res) {
          this.videoDevInfo = res
          this.setIndexVideo(res)
        }
      }
    },
    // 设置密码
    getPwd (data) {
      let key = this.ctlReqKey
      if (key) this[key](data)
    },
    //   宫格切换事件
    async playModelChange (code) {
      if (code === this.playNub) {
        return
      } else {
        // 根据code 注销视频
        this.destroyPlay(code)
        // 当为单宫格时，播放单个视频
        if (code === 'one') {
          this.defaultOpenMenu = ['', this.devActiveId]
        } else {
          if (this.defaultOpenMenu)
            this.defaultOpenMenu = []
          // 多宫格时，请求看板列表数据
          this.reqIotVideoBoardList(code)
        }
      }
    },
    // 新增播放视频   
    async addVideoChange (val) {
      let { data, i } = val
      const typeInfo = {
        four: 1,
        six: 2,
        nice: 3
      }
      // 添加视频看板
      this.reqAddIotVideoBoard({ devId: data, order: i, type: typeInfo[this.playNub] })
    },
    async delVideo (item, i) {
      let { flvPlayer, videoInfo } = item
      const res = await this.$api.DelIotVideoBoard({ id: videoInfo.id })
      if (res) {
        let data = [videoInfo.devId, i, this.playNub]
        let nowDevId = splitOrMergeId(data)
        if (this.playVideoActiveId == nowDevId) {
          this.videoDevInfo = null
          this.playVideoActiveId = ''
        }
        if (flvPlayer) flvPlayer.destroy()
        this.$set(this.videoPlayList, i, '')
        this.$msg.success('看板视频删除成功');
      }
    },
    async picLoad () {
      this.picLoading = true
      let addIndex = this.picFromInfo.pageIndex + 1
      this.$set(this.picFromInfo, 'pageIndex', addIndex)
      const res = await this.reqIotDevicePhotoPageList()
      if (res) {
        this.picTotal = res.totalCount
        this.picList.push(res.list)
        this.picLoading = false
      }
    },
    splitColse () {
      this.isSplit = false
      this.picTotal = 0
      this.picList = []
      this.picFromInfo = {
        pageSize: 14,
        pageIndex: 1,
        timeType: 1,
        sTime: '',
        eTime: ''
      }
    },
    // 注销过期视频   
    destroyPlay (code) {
      code = code || this.playNub
      const nubIndex = {
        one: 1,
        four: 4,
        nice: 9,
        six: 6
      }
      let arrList = []
      this.videoPlayList.forEach(item => {
        if (item.flvPlayer) item.flvPlayer.destroy()
      })
      if (!code) return
      for (let i = 0; i < nubIndex[code]; i++) {
        arrList.push('')
      }
      this.videoPlayList = arrList
    }
  }
}
</script>

<style lang="scss" scoped>
.video_main_box {
  .right_content_box {
    border-radius: 8px;
    box-sizing: border-box;
    padding: 40px 40px 20px 40px;
    & > div:first-child {
      display: flex;
      width: 100%;
      height: calc(100% - 45px);
      .video_content_box {
        flex: 1;
        height: 100%;
        perspective: 1000px;
        .flipWarp {
          transition: transform 2s;
          transform-style: preserve-3d;
          transform: rotateY(0deg);
          & > div {
            width: 100%;
            height: 100%;
            position: absolute !important;
            backface-visibility: hidden;
          }
          & > div:last-child {
            width: 100%;
            height: 100%;
            transform: rotateY(180deg);
            .nowPicTitle {
              width: 100%;
              height: 38px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              box-sizing: border-box;
              background: #fff;
              padding: 0 15px;
              font-size: 16px;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 1000;
              color: #777e83;
              font-weight: 600;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12),
                0 0 6px rgba(0, 0, 0, 0.04);
              & > p {
                display: flex;
                & > span:first-child {
                  font-size: 24px;
                  margin-right: 8px;
                }
              }
              & > span {
                font-size: 22px;
              }
            }
          }
        }
        .flip180 {
          transform: rotateY(180deg);
        }
      }
      .video_info_box {
        width: 372px;
        height: 100%;
        margin-left: 10px;
        .xiangce_box {
          font-size: 26px;
          position: absolute;
          right: 15px;
          top: 0px;
        }
        .video_ctl_main_box {
          width: 212px;
          height: 212px;
          margin: 0 auto;
          border-radius: 100%;
          border: 5px solid #42d3b1;
          overflow: hidden;
          position: relative;
          background: #e6e6e6;
          //   .leftTriangle{
          //       .rightjt{
          //          font-size: 20px;
          //          color: red;
          //          transform: translateX(-40px);
          //       }
          //   }
          & > div:nth-child(1) {
            position: absolute;
            left: 152px;
            transform: rotate(135deg);
            .iconfont {
              position: absolute;
              left: 28px;
              top: 32px;
              z-index: 100;
              font-size: 40px;
              color: #e6e6e6;
              transform: rotate(134deg);
            }
          }
          & > div:nth-child(2) {
            position: absolute;
            transform: rotate(-135deg);
            top: 152px;
            .iconfont {
              position: absolute;
              left: 28px;
              top: 32px;
              z-index: 100;
              font-size: 40px;
              color: #e6e6e6;
              transform: rotate(134deg);
            }
          }
          & > div:nth-child(3) {
            position: absolute;
            transform: rotate(45deg);
            top: -152px;
            .iconfont {
              position: absolute;
              left: 28px;
              top: 32px;
              z-index: 100;
              font-size: 40px;
              color: #e6e6e6;
              transform: rotate(134deg);
            }
          }
          & > div:nth-child(4) {
            position: absolute;
            transform: rotate(-45deg);
            left: -152px;
            .iconfont {
              position: absolute;
              left: 28px;
              top: 32px;
              z-index: 100;
              font-size: 40px;
              color: #e6e6e6;
              transform: rotate(134deg);
            }
          }
          .notClick {
            cursor: not-allowed;
          }
          .picture_box {
            width: 100px;
            height: 100px;
            border-radius: 50px;
            border: 1px solid #e6e6e6;
            background: #fff;
            z-index: 10;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
            & > div {
              width: 74px;
              height: 74px;
              border-radius: 100%;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              background: linear-gradient(to top, #008ac4, #00c497);
              display: flex;
              align-items: center;
              justify-content: center;
              & > span {
                display: inline-block;
                width: 37px;
                height: 37px;
                font-size: 36px;
                color: #fff;
              }
            }
          }
          .notCtl_box {
            width: 212px;
            height: 212px;
            border-radius: 100%;
            border: 5px solid #42d3b1;
            position: absolute;
            left: -5px;
            top: -5px;
            z-index: 120;
            background: #e6e6e6;
            display: flex;
            align-items: center;
            justify-content: center;
            & > span {
              font-size: 68px;
            }
          }
        }
        .tools_but_box {
          width: 272px;
          margin: 18px auto 20px auto;
          display: flex;
          justify-content: space-around;
          .but_box {
            width: 80px;
            color: #fff;
          }

          // .disabled:hover{
          //     background-color: #2F5259 !important;
          // }
          //  & > div {
          //     width: 80px;
          //     height: 34px;
          //     line-height: 34px;
          //     text-align: center;
          //     border-radius: 5px;
          //     & > span{
          //         font-size: 20px;
          //     }
          //  }
        }
        .dev_info_box {
          width: 272px;
          height: 400px;
          margin: 0 auto;
          padding: 20px 0;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          & > div {
            width: 100%;
            height: 40px;
            // line-height: 40px;
            padding: 0 35px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            font-size: 14px;
            & > span:first-child {
              min-width: 56px;
              flex-shrink: 0;
            }
            & > span:last-child {
              margin-left: 10px;
              flex: 1;
              display: block;
              max-width: 160px;
              text-align: center;
            }
          }
        }
        .disabled {
          cursor: not-allowed;
        }
      }
      .one_video_box {
      }
      .four_video_box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;

        & > li {
          width: 49.4%;
          height: 49%;
          overflow: hidden;
        }
        .videoActive {
          border: 1px solid #00c395;
          box-sizing: border-box;
        }
      }
      .nice_video_box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        & > li {
          width: 33%;
          height: 32.8%;
          overflow: hidden;
        }
        .videoActive {
          border: 1px solid #00c395;
          box-sizing: border-box;
        }
      }
      .six_video_box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 10px;
        .videoActive {
          border: 1px solid #00c395;
          box-sizing: border-box;
        }
        & > li:first-child {
          grid-area: 1/1/3/3;
        }
        & > li:not(:first-child) {
          max-height: 225px;
        }
      }
      .bordert {
        box-sizing: border-box;
        border: 1px solid transparent;
      }
      .statusNotOnlie {
        & > span:first-child {
          font-size: 62px;
        }
        & > span:last-child {
          margin-top: 18px;
        }
        .standby {
          color: #ff9000;
          position: relative;
        }
        .standby::after {
          width: 50px;
          height: 50px;
          background: rgba(#ff9000, 0.2);
          border-radius: 25px;
          position: absolute;
          left: -8px;
          top: -8px;
          content: "";
        }
        .offNine {
          color: #999;
          position: relative;
        }
        .offNine::after {
          width: 50px;
          height: 50px;
          background: rgba(#999, 0.2);
          border-radius: 25px;
          position: absolute;
          left: -18px;
          top: -14px;
          content: "";
        }
      }
    }
    & > div:last-child {
      width: 100%;
      height: 45px;
      line-height: 45px;
      .bottom_content_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > ul {
          display: flex;
          & > li {
            font-size: 24px;
            color: #5c656c;
            margin-right: 18px;
          }
          .modelActive {
            color: #d4cece;
          }
        }
        .video_tools_box {
          display: flex;
          align-items: center;
          .hd_box {
            font-size: 15px;
          }
        }
        .marginRight {
          margin-right: 382px;
        }
      }
    }
  }
  .delVideoIcon {
    font-size: 26px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
  }

  .album_mian_box {
    display: flex;
    flex-direction: column;
    .top_box,
    .tools_box {
      flex-shrink: 0;
      width: 100%;
      box-sizing: border-box;
    }
    .top_box {
      height: 50px;
      padding: 0 15px;
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #28353f;
      & > p {
        display: flex;
        align-items: center;
      }
      & > p > span:first-child {
        font-size: 26px;
        margin-right: 10px;
      }
      & > span {
        font-size: 22px;
      }
    }
    .tools_box {
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px 15px 15px;
      .date_picker_box {
        margin-top: 15px;
        margin-right: 25px;
      }
      ul.dateType_box {
        display: flex;
        //    width: 214px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #fff;
        border-radius: 15px;

        margin-top: 15px;
        & > li {
          padding: 0 28px;
          border-radius: 15px;
        }
        & > li:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
    .content_box {
      flex: 1;
      overflow: hidden;
      .infinite-list {
        box-sizing: border-box;
        padding-left: 15px;
        & > li {
          width: 170px;
          height: 100px;
          margin-right: 10px;
          float: left;
          margin-bottom: 10px;
          .el-image ::v-deep .image-slot {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
          }
        }
        .infinite-list-item {
          box-sizing: border-box;
          border: 1px solid transparent;
          & > p {
            font-size: 12px;
            text-align: center;
            padding: 3px 0;
            width: 100%;
            background: rgba(0, 0, 0, 0.7);
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
      & > div > p {
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
.alert_box {
  width: 280px;
  height: 32px;
  margin-left: 10px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<template>
    <div class="addVideo_main_box wh100 boxBg" :style="iconfs">
        <el-popover
        v-model="visible"
        @show= 'menuShow'
        placement="right"
        width="320"
        ref="popovers"
        popper-class= 'videoNavPop'
        trigger="click">
             <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                background-color="#545c64"
                text-color="#fff"
                @select='onSelect'
                active-text-color="#ffd04b">
                <template v-for="item of menu">
                  <el-submenu :index="item.id">
                    <template slot="title">
                      <i class="el-icon-menu"></i>
                      <span>{{ item.name }}</span>
                    </template>
                    <template v-if="item.devs && item.devs.length">
                        <el-menu-item v-for="val of item.devs" :index="val.devId">{{ val.devName }}</el-menu-item>
                    </template>
                    
                   </el-submenu>
                </template>
                
                </el-menu>
            <span slot="reference" class="add_box el-icon-circle-plus-outline cp"></span>
        </el-popover>
      
    </div>
</template>

<script>
    export default {
        props:{
            fs:{
                type: String,
                default: '40px'
            },
            menus:{
                type: Array,
                default: () => []
            },
            i:{
                type: Number
            }
        },
        data(){
           return{
               visible: false,
              
           }
        },
        computed:{
            iconfs(){
               return {
                '--fs': this.fs
               }
            },
            menu(){
                
               return this.menus
            }
           
        },
        methods:{
            onSelect(val) {
               this.visible= false
               this.$emit('change',{
                   data: val,
                   i: this.i
               })
            },
            menuShow() {

            }
        }
    }
</script>

<style lang="scss">
 .addVideo_main_box{
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     .add_box{
         color: #999;
         font-size: var(--fs);
     }
 }
 .el-menu-vertical-demo{
     height: 400px;
     overflow-y: auto;
 }
 .videoNavPop{
    padding: 0 !important;
    box-sizing: border-box;

 }
</style>